<template>
  <div style="display: flex">
    <navigation index="storeHome" ref="navigation">
      <div
        class="topnavigation"
        style="display: flex; justify-content: space-between"
      >
        <el-button
          v-if="UserInfo.RolesArray.indexOf('StoreAdmin') > -1"
          style="margin-left: 10px"
          type="primary"
          @click="skip('renew')"
        >
          {{ tools.GetLanguageValue("web.续费") }}</el-button
        >
        .
        <el-badge :value="CommonAdvertList.length" style="margin-right: 30px">
          <el-button @click="CommonAdvertListWindow = true">
            <el-icon size="16"><Bell /></el-icon>
            {{ tools.GetLanguageValue("web.通知") }}</el-button
          >
        </el-badge>
      </div>

      <div style="width: 100%; margin-top: 10px">
        <el-card
          class="box-card"
          style="
            margin-top: 100px;
            max-width: calc(100% - 30px);
            margin: 0 auto;
            border: 1px solid var(--el-menu-border-color);
          "
        >
          <template #header>
            <span>{{ tools.GetLanguageValue("web.我的工作") }}</span>
          </template>

          <el-row style="padding: 20px; text-align: center">
            <el-col :span="2">
              <el-statistic
                @click="toRepairs(200)"
                :title="tools.GetLanguageValue('web.维修中')"
                :value="MyWork.RepairRepairingCount"
              />
            </el-col>
            <el-col :span="2">
              <el-statistic
                @click="toRepairs(0)"
                :title="tools.GetLanguageValue('web.待开始')"
                :value="MyWork.RepairPendingCount"
              />
            </el-col>
            <el-col :span="2">
              <el-statistic
                @click="toRepairs(100)"
                :title="tools.GetLanguageValue('web.预定')"
                :value="MyWork.RepairReserveCount"
              />
            </el-col>
            <el-col :span="2">
              <el-statistic
                @click="toRepairs(700)"
                :title="tools.GetLanguageValue('web.返修')"
                :value="MyWork.ReworkingCount"
              />
            </el-col>
            <!-- <el-col :span="2">
              <el-statistic
                :title="tools.GetLanguageValue('web.已完成')"
                :value="MyWork.RepairFinshCount"
              />
            </el-col> -->
          </el-row>
        </el-card>

        <br />
        <el-card
          class="box-card"
          style="
            margin-top: 100px;
            max-width: calc(100% - 30px);
            margin: 0 auto;
            border: 1px solid var(--el-menu-border-color);
          "
        >
          <template #header>
            <span> {{ tools.GetLanguageValue("web.我的信息") }} </span>
          </template>
          <div id="main" style="width: 100%">
            <el-form
              ref="form"
              :model="UserInfo"
              label-width="170px"
              style="margin: 10px 0px"
            >
              <el-row>
                <el-col :span="6">
                  <el-form-item :label="tools.GetLanguageValue('web.头像')">
                    <el-upload
                      class="avatar-uploader"
                      :action="UploadPictureImageUrl"
                      :show-file-list="false"
                      :on-success="UploadProductSuccess"
                      :before-upload="UploadProductSuccess"
                      :headers="headersImage"
                    >
                      <el-image
                        v-if="UserInfo.ProfilePhotoFormat"
                        :src="UserInfo.ProfilePhotoFormat"
                        class="avatar"
                      >
                        <template #error>
                          <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </template>
                      </el-image>
                      <el-icon v-else class="avatar-uploader-icon"
                        ><Plus
                      /></el-icon>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="18">
                  <el-form-item :label="tools.GetLanguageValue('web.姓名')">
                    <el-input v-model="UserInfo.Name"></el-input>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.邮箱')">
                    <el-input v-model="UserInfo.Email"></el-input>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.联系方式')">
                    <el-input v-model="UserInfo.Phone"></el-input>
                  </el-form-item>
                  <el-form-item :label="tools.GetLanguageValue('web.修改密码')">
                    <el-input v-model="UserInfo.Password"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="width: 100%; text-align: center">
                <el-button
                  style="width: 150px; height: 40px"
                  @click="UpdateUserInfo"
                  type="primary"
                  >{{ tools.GetLanguageValue("web.保存") }}</el-button
                >
              </div>
            </el-form>
          </div>
        </el-card>
        <br />
      </div>

      <el-drawer
        v-model="CommonAdvertListWindow"
        title="通知列表"
        direction="rtl"
        size="550"
      >
        <el-table :data="CommonAdvertList">
          <el-table-column property="Title" label="标题" width="350" />

          <el-table-column :label="tools.GetLanguageValue('web.操作')">
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="DetailCommonAdvert(scope.row)"
              >
                {{ tools.GetLanguageValue("web.查看") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-drawer>

      <el-dialog
        v-model="DetailCommonAdvertWindow"
        :title="DetailCommonAdvertOpt.Title"
        width="700"
      >
        <div v-html="DetailCommonAdvertOpt.Value"></div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="DetailCommonAdvertWindow = false"
              >Cancel</el-button
            >
          </div>
        </template>
      </el-dialog>

      <!-- 过期提示 -->
      <el-dialog
        :title="tools.GetLanguageValue('web.到期提醒')"
        v-model="ExpireDateTipsWindow"
        width="700"
      >
        <div>
          <p style="font-size: 16pt">
            {{ tools.GetLanguageValue("web.剩余到期时间") }}
            <span style="color: red; font-size: 25pt">
              {{ UserInfo.SurplusDay }}
            </span>
          </p>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="ExpireDateTipsWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="DonotPrompt()">
              {{ tools.GetLanguageValue("web.关闭前不再提示") }}</el-button
            >
            <el-button type="danger" @click="skip('renew')">续费</el-button>
          </div>
        </template>
      </el-dialog>
    </navigation>
  </div>
</template>
<script>
import axios from "../../commons/AxiosMethod.js";
import tools from "../../commons/tools.js";
import navigation from "../../components/Modules/Navigation.vue";
import BoxGridPassword from "../../components/BoxGridPassword.vue";
import {
  ElMessageBox,
  ElMessage,
  ElNotification,
  UploadProps,
} from "element-plus";
import { CloseBold, Plus, Delete, Bell } from "@element-plus/icons-vue";
import * as echarts from "echarts";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      Password: "1,2,3,4,5",
      BusinessVolume: {
        OrderTotal: "",
        PartOrderTotal: "",
        DayOrderTotal: "",
      },
      BusinessVolumeWhere: {},
      OrderCount: {},
      TrendBusinessVolumeWhere: {},
      TrendBusinessVolume: {},
      UserInfo: {
        RolesArray: [],
      },
      tools: tools,
      headersImage: {},
      UploadPictureImageUrl: "",
      MyWork: {},
      CommonAdvertList: [],
      CommonAdvertListWindow: false,
      DetailCommonAdvertWindow: false,
      DetailCommonAdvertOpt: {},

      DonotPromptWindow: "",
      ExpireDateTipsWindow: false,
    };
  },
  name: "App",
  mounted() {
    this.DonotPromptWindow = sessionStorage.getItem("DonotPrompt") != "YES";

    this.GetMyUserInfo();
    this.GetMyWork();
    this.GetMyCommonAdvert();

    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.UploadFullImageFileUrl =
      axios.GetUrl() + "/api/Upload/FullImageFileWebp";

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.$refs.navigation.onresize();
      })();
    };
  },

  components: {
    navigation,
    Plus,
    Bell,
    BoxGridPassword,
  },
  methods: {
    DonotPrompt() {
      this.ExpireDateTipsWindow = false;
      sessionStorage.setItem("DonotPrompt", "YES");
    },
    toRepairs(item) {
      this.$router.push({
        path: "repairs",
        query: {
          status: item,
        },
      });
    },
    //查看详细
    DetailCommonAdvert(item) {
      this.DetailCommonAdvertOpt = item;
      this.DetailCommonAdvertWindow = true;
    },
    //获取可用的广告列表
    GetMyCommonAdvert() {
      axios.apiMethod(
        "/stores/Data/GetMyCommonAdvert",
        "post",
        {},
        (result) => {
          this.CommonAdvertList = result.Data;

          if (this.CommonAdvertList.length > 0) {
            ElNotification({
              title: this.CommonAdvertList[0].Title,
              offset: offset,
              dangerouslyUseHTMLString: true,
              message: this.CommonAdvertList[0].Value,
              type: "warning",
            });
            return;
          }

          var offset = 0;
          this.CommonAdvertList.forEach((e) => {
            offset += 100;
            ElNotification({
              title: e.Title,
              offset: offset,
              dangerouslyUseHTMLString: true,
              message: e.Value,
              type: "warning",
            });
            return;
          });
        }
      );
    },

    GetMyWork() {
      axios.apiMethod("/stores/User/GetMyWork", "get", {}, (result) => {
        this.MyWork = result.Data;
      });
    },
    skip(url) {
      this.$router.push({
        path: url,
        query: {
          s: Date.now(),
        },
      });
    },
    GetMyUserInfo() {
      axios.apiMethod("/stores/User/GetMyUserInfo", "get", {}, (result) => {
        this.UserInfo = result.Data;
        // if (result.Data.IsTipsExpireDate) {
        //   if (this.DonotPromptWindow) {
        //     this.ExpireDateTipsWindow = true;
        //   } 
        //   // alert("快到期啦，还剩：" + result.Data.SurplusDay + "天");
        // }
      });
    },
    UploadProductSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.UserInfo.ProfilePhotoFormat = response.Data.ImageFormet;
        this.UserInfo.ProfilePhoto = response.Data.FileName;
      }
    },

    // 修改个人信息
    UpdateUserInfo() {
      axios.apiMethod(
        "/stores/User/UpdateUserInfo",
        "post",
        this.UserInfo,
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              title: "提示",
              message: "操作成功",
              type: "success",
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}

.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  border: 1px dashed #666;
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  text-align: center;
}
</style>
